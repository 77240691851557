/* Fonts */

/* M for Museo, 700 for font format, 28 for font-size */
/*Museo, 700 - 28px*/
.M700-28 {
    font-family: Museo700;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
}
/*Museo, 300 - 28px*/
.M300-28 {
    font-family: Museo300;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
}
/*Museo, 700 - 113px*/
.M700-113 {
    font-family: Museo700;
    font-size: 113px;
    line-height: 135px;
    color: #000000;
}
/*Museo, 300 - 113px*/
.M300-113 {
    font-family: Museo300;
    font-size: 113px;
    line-height: 135px;
    color: #000000;
}
/*Museo, 500 - 18px*/
.M500-18 {
    font-family: Museo500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
/*Museo, 700 - 16px*/
.M700-16 {
    font-family: Museo700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
/*Source Sans Pro, Semibold - 16px*/
.SSPS-16 {
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
/*Museo, 700 - 14px*/
.M700-14 {
    font-family: Museo700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}
/*Museo, 300 - 22px*/
.M300-22 {
    font-family: Museo300;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
}
/*Museo, 700 - 22px*/
.M700-22 {
    font-family: Museo700;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
}
/*Museo, 300 - 26px*/
.M300-26 {
    font-family: Museo300;
    font-size: 26px;
    line-height: 31px;
    color: #727272;
}
/*Museo, 300 - 26px*/
.M300-26 {
    font-family: Museo300;
    font-size: 26px;
    line-height: 31px;
    color: #FFDD00;
}
/*Museo, 700 - 26px*/
.M700-26 {
    font-family: Museo700;
    font-size: 26px;
    line-height: 31px;
    color: #000000;
}
/*Museo, 300 - 67px*/
.M300-67 {
    font-family: Museo300;
    font-size: 67px;
    line-height: 80px;
    color: #FFFFFF;
}
/*Museo, 300 - 18px*/
.M300-18 {
    font-family: Museo300;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}